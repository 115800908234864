class ProjectItem {
  constructor(record) {
    const {
      project = null,
      id = null,
      name = '',
      complete_size = '',
      expand_size = '',
      pound = '',
      color = '',
      quantity = 0,
      unit = '',
      is_deleted = false,
    } = record || {};
    this.project = project;
    this.id = id;
    this.name = name;
    this.complete_size = complete_size;
    this.expand_size = expand_size;
    // this.pound = pound;
    // this.color = color;
    this.quantity = quantity;
    this.unit = unit;
    this.is_deleted = is_deleted;
  }
}

ProjectItem.labels = {
  project: '專案編號', // related to  project
  id: '項目編號', //（item id)
  name: '項目名稱', //任意填->會影響之後的報價表項目
  complete_size: '完成尺寸', //任意填寫的欄位
  expand_size: '展開尺寸', //任意填寫的欄位
  // pound: '紙質磅數', //拿紙類製程的服務內容顯示
  // color: '顏色', //拿印刷製程的印刷色數顯示
  quantity: '數量', //每個項目自己一個數量。單位要可以任意填
  unit: '單位',
};

module.exports = ProjectItem;
