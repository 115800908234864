const ProjectItem = require('./ProjectItem');

class Quotation {
  constructor(record = {}) {
    this.id = '';
    this.serial_number = null;
    this.display_id = '';
    this.version = 1;
    this.name = '';
    this.created = parseInt(new Date().getTime());
    this.quote_date = parseInt(new Date().getTime());
    this.delivery_date = parseInt(new Date().getTime());
    this.is_deal = false;
    this.deal_date = parseInt(new Date().getTime());
    this.note = '';
    this.sales = 0;
    this.tax = 0;
    this.total = 0;

    this.owner = null;
    this.owner_name = '';
    this.staff = null;
    this.staff_name = '';
    this.staff_fullname = '';
    this.void = false;
    this.items = [];

    Object.assign(this, record);
  }
}

Quotation.labels = {
  id: '系統編號',
  serial_number: '流水號',
  display_id: '報價單號', //：Q20210111X-0001  (新單)->Quotation+日期+key單人員名字的第一個英文字母+流水號每天不需重置
  version: '版本',
  name: '報價單名稱',
  created: '開立時間',
  quote_date: '報價時間',
  delivery_date: '交貨時間',
  is_deal: '成交',
  deal_date: '成交時間',
  note: '備註',
  sales: '銷售額',
  tax: '稅額',
  total: '總計', //：將全部報價單項目總價加總
  owner: '客戶編號',
  owner_name: '客戶名稱',
  staff: '業務編號',
  staff_name: '業務名稱', // for 工單報價單,
  staff_fullname: '業務名稱',
  void: '取消',

  items: '專案項目', // Class Item
};

class Item {
  constructor(record) {
    this.project = null;
    this.item_index = 0;
    this.dispatch = null;
    this.unit_cost = 0;
    this.unit_price = 0;
    this.cost = 0;
    this.price = 0;
    this.times = 1.0;

    Object.assign(this, record);
  }
}

Item.labels = {
  ...ProjectItem.labels,
  project: '專案編號',
  item_index: '印件編號',
  dispatch: '工單編號',
  unit_cost: '單價成本',
  unit_price: '單價', //：預設顯示工單項目價格乘N為報價單項目單價，可直接改成新價格
  cost: '項目成本',
  price: '項目金額', //：預設顯示工單乘項目價格乘N為報價單項目單價再乘數量為報價單項目總價，可直接改成新價格
  times: '倍數',
};

Item.calc = function (item, quantity) {
  return item.unit_price * quantity;
};

Quotation.Item = Item;

module.exports = Quotation;
