import React, {
  useRef,
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import styled from 'styled-components';
import {Table, Button, Modal, Input} from 'antd';
import {Context} from '../../AppContext';
import Quotation from '../../Models/Quotation';
import {Link} from 'gatsby';
import {date} from '../../Utils';
import {Select, SelectOption} from '../../Widgets';

const labels = Quotation.labels;
const PAGINATION_INIT = {
  current: 1,
  pageSize: 20,
};

export default function QuotationListPage(props) {
  const app = useContext(Context);
  const [records, setRecords] = useState(null);
  const [filters, _setFilters] = useState({
    is_deal: 'yes',
    ...PAGINATION_INIT,
  });
  const [search, setSearch] = useState('');
  const [total, setTotal] = useState(0);

  const columns = [
    {
      title: labels.display_id,
      key: 'id',
      render: (record) => (
        <Link to={`/quotation?id=${record.id}`}>
          {record.display_id || '無單號'}
        </Link>
      ),
    },
    {
      title: labels.version,
      key: 'version',
      dataIndex: 'version',
    },
    {
      title: labels.name,
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: labels.owner_name,
      key: 'owner_name',
      dataIndex: 'owner_name',
    },
    {
      title: labels.deal_date,
      key: 'deal_date',
      render: (record) => date.format(record.deal_date),
    },

    {
      title: labels.delivery_date,
      key: 'delivery_date',
      render: (record) => date.format(record.delivery_date),
    },
    {
      title: labels.total,
      key: 'total',
      dataIndex: 'total',
    },
    {
      title: labels.is_deal,
      key: 'is_deal',
      render: (record) => (
        <div style={{color: '#b81212'}}>{record.is_deal ? '賀！' : ''} </div>
      ),
    },
  ];

  const getRecords = useCallback(
    async (search = '') => {
      app.actions.setLoading(true);
      try {
        const params = {
          query: {
            // username: {$regex: search},
            ...(filters.is_deal && {
              is_deal: {yes: true, no: false}[filters.is_deal],
            }),
          },
          paging: {
            offset: (filters.current - 1) * filters.pageSize,
            limit: filters.pageSize,
          },
          sorting: ['+delivery_date'],
        };
        let resp = await app.actions.getQuotations(params);
        setRecords(resp.results);
        setTotal(resp.total);
      } catch (ex) {
        console.warn(ex);
      }
      app.actions.setLoading(false);
    },
    [app.actions, filters],
  );

  function setFilters(obj) {
    _setFilters((prev) => ({
      ...prev,
      ...PAGINATION_INIT,
      ...obj,
    }));
  }

  useEffect(() => {
    getRecords();

    return () => setRecords([]);
  }, [getRecords]);

  return (
    <Wrapper>
      <div className="action-bar">
        <Select
          value={filters.is_deal}
          onChange={(value) => setFilters({is_deal: value})}>
          <SelectOption key="不限" value="">
            不限
          </SelectOption>
          <SelectOption key="已成交" value="yes">
            已成交
          </SelectOption>
          <SelectOption key="未成交" value="no">
            未成交
          </SelectOption>
        </Select>
        {/* <Input
          value={search}
          placeholder="搜尋姓名"
          style={{width: 250}}
          onChange={(e) => setSearch(e.target.value)}
        /> */}
        <Button type="primary" onClick={() => getRecords(search)}>
          刷新
        </Button>
        <div style={{flex: 1}} />
        {/* <Button type="primary">匯出清單</Button> */}
      </div>

      {records && (
        <Table
          columns={columns}
          dataSource={records}
          rowKey="id"
          pagination={{
            pageSize: filters.pageSize,
            current: filters.current,
            total,
          }}
          onChange={(pagination) =>
            setFilters({
              pageSize: pagination.pageSize,
              current: pagination.current,
            })
          }
        />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 72px 40px;
  background-color: white;

  & > .action-bar {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    & > button {
      margin-left: 10px;
    }
  }
`;
